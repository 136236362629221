import React, { Component } from 'react';

import classname from 'classnames';

import Style from './FJResourceIntroduction.module.less';
import FJSrcUtil from '../../../src/util/FJUtil';
import FJUtil from '../../../util/FJUtil';
import FJConfig from '../../../src/config/FJConfig';

export default class FJResourceIntroduction extends Component {
  constructor(props) {
    super(props);
    this.numberContainer = [];
    this.ulRef = React.createRef();
    this.isAddAnimation = React.createRef();
  }

  __drawIcon() {
    const { iconList = ['One-click application', 'Free for commercial use', 'Constantly updated'] } = this.props;

    let iconItem =
      Array.isArray(iconList) &&
      iconList.map(item => {
        return (
          <div className={Style.resourceIconItem} key={item}>
            <span className={Style.resourceIconListItem} />
            {item}
          </div>
        );
      });

    return <div className={Style.resourceIconBox}>{iconItem}</div>;
  }

  __drawResourceIntroductionItem() {
    const { list = [], useAnimation = false } = this.props;
    return (
      Array.isArray(list) &&
      list.map((item, index) => {
        return (
          <li
            className={classname(
              Style.resourceIntroductionItemItem,
              useAnimation ? Style.resourceIntroductionItemItemStartPosition : '',
            )}
            key={index}
            style={item.style}
          >
            <div>
              <span ref={e => (this.numberContainer[index] = e)}>{item.number}</span>
              <div>{item.desc}</div>
            </div>
            <video
              className={classname(Style.videoPlayer)}
              src={item.video}
              width='480'
              height='340'
              autoPlay={true}
              preload={'metadata'}
              muted={true}
              loop={true}
              playsInline={true}
              controls={false}
            />
          </li>
        );
      })
    );
  }

  resourceBlockMoveToCenter = () => {
    const ulElement = this.ulRef.current;

    if (ulElement) {
      this.isAddAnimation.current = true;
      const { top, height } = ulElement.getBoundingClientRect();
      if (top < Math.max(window.innerHeight / 4, window.innerHeight - height / 4)) {
        // 遍历 li 元素并给它们添加类名,添加动画
        const liElements = ulElement.querySelectorAll('li');
        liElements.forEach((li, index) => {
          li.style.setProperty('--d', index * 0.1);
          li.classList.add(Style.resourceIntroductionItemItemAnimation);
        });

        const { list } = this.props;
        for (let i = 0; i < liElements.length; i++) {
          let obj = list[i];
          const span = liElements[i].querySelector('span');
          FJSrcUtil.numberAnimation(
            obj.duration,
            obj.from,
            obj.to,
            val => {
              if (this.numberContainer[i]) {
                this.numberContainer[i].textContent = val + (obj.unit || '');
              }
            },
            () => {
              span.textContent = obj.number;
            },
          );
        }

        FJUtil.removeEvent(window, 'scroll', this.resourceBlockMoveToCenter);
      }
    }
  };

  componentDidMount(prevProps, prevState, snapshot) {
    const { useAnimation } = this.props;
    if (useAnimation) {
      FJUtil.addEvent(window, 'scroll', this.resourceBlockMoveToCenter);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.useAnimation === false && this.props.useAnimation && !this.isAddAnimation.current) {
      FJUtil.addEvent(window, 'scroll', this.resourceBlockMoveToCenter);
    }
  }

  componentWillUnmount() {
    // 防止在执行过程中切换页面或者dom，防止报错
    FJUtil.removeEvent(window, 'scroll', this.resourceBlockMoveToCenter);
  }

  render() {
    const { title, titleClass = '' } = this.props;

    return (
      <div className={Style.resourceContainer}>
        {FJSrcUtil.__drawHTag('h2', title, classname(Style.drawResourceIntroductionTitle, titleClass))}
        {this.__drawIcon()}
        <ul className={Style.resourceIntroductionBox} ref={this.ulRef}>
          {this.__drawResourceIntroductionItem()}
        </ul>
      </div>
    );
  }
}
